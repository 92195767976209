body {
  font-family: 'Arial';
  font-size: 22px;
  background-color: #F6F6DB;
}

/* Home page and important stuff*/
.impTitles {
  margin-inline: auto;
  font-weight: 800;
  box-shadow: 10px 10px 10px #6a7a55;
  width: 60%;
  border-radius: 5px;
  padding: 5px 0px 5px 0px;
  background-color: transparent;
}


/* Navbar Logo Animations */
@keyframes typing {
  0% {
    margin-left: 0;
    width: 430px;
  }

  10% {
    border-left: 2px solid chartreuse;
  }

  98% {
    border-left: 2px solid chartreuse;
  }

  100% {
    margin-left: 430px; /* Same margin-left as width = perfect typing animation */
    width: 0px;
  }
}

.navbar {
  background-color: #38422B;
  padding: 0px 10px 0px 10px;
}

.navbar-brand {
  margin-right: 35px;
  font-size: 30px;
  font-family: 'Script';
  /* Apply the cursive font */
}

.navbar-brand::before {
  position: absolute;
  content: "";
  display: block;
  height: 40px;
  /* Adjust height if necessary */
  background-color: #38422B;
  animation: typing 2s steps(40) forwards;
  /* Adding delay after each iteration */
}

/* -- ANIMATION END -- */

/* Fancy Horizontal Bar */
hr {
  width: 80%;
  padding: 1px;
  border-radius: 10px;
  background-color: #697157;
  justify-content: center;
  /*background-color: rgba(128, 128, 128, 0.488); <- old*/
}

/* Some future work
h1::after{
    content: "";
    justify-content: center;
    display: block;
    width: 100%;
    height: 3px;
    padding: 1px;
    border-radius: 5px;
    background-color: #697157;
}*/

/* Search Bar Styling */
.searchWrapper {
  display: block;
  height: 53px;
  width: 40%;
}

.searchComponent {
  padding: 0;
  display: flex;
}

.searchBox {
  padding: 10px 20px 10px 20px;
  border: none;
  background-color: #F6F6DB;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: black;
  font-weight: 500;
  width: 75%;
}

.searchBtn {
  color: white;
  background-color: #9AA581;
  border: none;
  text-align: center;
  padding: 10px 20px 10px 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: none;
  cursor: pointer;
  transition: 0.5s;
  width: 25%;
}

.searchBtn:hover {
  border-radius: 1px;
  background-color: #697157;
  color: rgb(34, 184, 56);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.searchResults {
  background-color: #F6F6DB;
  border: solid 2px #ffff54;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 100%;
  overflow: hidden;
  z-index: 2;
}

.searchResultsActive {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.searchResults a {
  font-weight: bold;
  padding: 10px 30px 10px 30px;
  display: block;
  cursor: pointer;
  transition: 1s;
  animation: slide 1s forwards;
}

@keyframes slide {
  0% {
    left: -100px;
    position: relative;
  }

  100% {
    left: 0px;
    position: relative;
  }
}

.searchResults a:hover {
  background-color: #f6f6af;
  border-radius: 20px;
}


/* -- END SEARCH BAR STYLING -- */
@media only screen and (max-width: 1250px) {
  li {
    font-size: 18px;
  }

  .searchWrapper {
    width: 40%;
  }
}

@media only screen and (max-width: 990px) {

  .navbar {
    padding: 10px 10px 10px 10px;
  }

  .searchWrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {

  .eventCardsContainer{
    padding-bottom: 15px;
  }

  /* Navbar Logo Animations */
@keyframes typing {
  0% {
    margin-left: 0;
    width: 320px;
  }

  10% {
    border-left: 2px solid chartreuse;
  }

  98% {
    border-left: 2px solid chartreuse;
  }

  100% {
    margin-left: 320px; /* Same margin-left as width = perfect typing animation */
    width: 0px;
  }
}

  .nav-item{
    margin: 15px 0px 15px 0px;
    border-bottom: #F6F6DB solid 1px;
  }

  .navbar {
    padding: 10px 10px 10px 10px;
  }

  .navbar-brand {
    font-size: 20px;
  }

  .navbar-brand::before {
    height: 30px;
  }

  .searchWrapper {
    width: 100%;
  }
}

/* These are the nav-link's style */
li:not(#loginnav) {
  position: relative;
  padding: 5px 12px 5px 12px;
  /* 10px 15px 10px 15px;  0.5vh 2vh 0.5vh 2vh;*/
  border-radius: 5px;
  margin: 5px 5px 5px 5px;
  border: #38422B solid;
  transition: 0.5s;
}

li:not(.carousel-indicators li)::after {
  content: '';
  position: absolute;
  height: 10px;
  width: 0;
  background-color: #6E7753;
  border-radius: 20px;
  transition: width 1s;
  left: 50%;
  transform: translateX(-50%);
}

/* The style for the login nav items */
#loginnav {
  color: white;
}

#loginnav:hover,
#loginnav:active {
  color: white;
}

#tab-login active {
  background-color: #6E7753;
}

/* Style for the nav items other than the login nav */
li:not(#loginnav):hover,
.lactive {
  color: #CCD5C0;
  border: #6E7753 solid;
}

li:not(#loginnav):hover::after,
.lactive::after {
  width: 50%;
}

/* Have to design one for the actual div and the other one for the pseudo element */

li:not(#loginnav).isActive {
  color: #CCD5C0;
  border: #b8bda9 solid;
}

li:not(#loginnav).isActive::after {
  background-color: #b8bda9;
  width: 50%;
}

/* -- END NAV-LINKS STYLING */

/* CONTACT & ABOUT PAGE */
.indented-paragraph {
  text-indent: 15px;
  font-family: 'Georgia, serif';
  margin-left: 20px;
  /* Add left margin to each paragraph */
  margin-right: 20px;
  /* Add right margin to each paragraph to balance the text */
  margin-bottom: 15px;
  /* Add space between paragraphs */
}

.contactInput {
  width: 100%;
  padding: 8px;
  /* Reduced padding */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 4px;
  /* Reduced margin */
  margin-bottom: 8px;
  /* Reduced margin */
  resize: vertical;
  font-size: 14px;
  /* Reduced font size */
}

.submitButton {
  background-color: #9AA581;
  color: white;
  padding: 8px 16px;
  /* Correct syntax for padding */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  /* Correct syntax for font size */
}

.submitButton:hover {
  background-color: #697157;
  /* Correct syntax for hover background color */
}

/* -- END CONTACT PAGE STYLING -- */

/* Carousel Title Animation-  not adding just for fun*/

@keyframes titletyping {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.carouseltitle {
  overflow: hidden;
  white-space: nowrap;
  border-right: 4px solid;
  animation: titletyping 4s steps(20, end) infinite;
  text-align: center;
  color: ghostwhite;
  font-size: 4vw;
  /* Responsive font size */
  font-family: Arial, sans-serif;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1vw;
  /* Responsive padding */
  border-radius: 1vw;
  /* Responsive border radius */
}

/* -- END Carousel Title -- */

/* Links hover colors */
.link {
  color: #13703dcc;
}

/* address colors class */
.link:hover {
  color: #aee745;
}

/* address colors hover class */

.linkweb {
  color: #be8417ea;
}

/* websites colors class */
.linkweb:hover {
  color: #7a641a;
}

/* websites colors hover class */

a[href^="tel:"] {
  color: rgba(38, 106, 146, 0.815);
  text-decoration: underline;
}

a[href^="tel:"]:hover {
  color: #2fcfdab4;
}

/* Links hover colors END */

/* Card Layout Styles */

.card-resources {
  display: block;
  /* background-color: rgba(47, 77, 6, 0.938); */
}

.card-container {
  background-image: url('https://images.rawpixel.com/image_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvcm00MjItMDQ3LWtxOTJ3eDl5LmpwZw.jpg');
  /* URL of the background image */
  background-repeat: no-repeat;
  background-size: cover;
  perspective: 1000px;
  position: relative;
  /* width: calc(35% - 38px); /* Adjust the width as needed */
  width: 80%;
  margin-inline: auto;
  margin-bottom: 20px;
  height: 70vh;
  max-height: 500px;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  /* Add a subtle box shadow */

}

.cardR {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: transform 0.8s;
  border-radius: 10px;
}

.card-front {
  color: #481F01;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 2.5rem;
  /* Increased font size */
  font-family: Times, cursive;
  line-height: 1.5;
  padding: 8px;
  text-align: center;
  border-radius: 10px;
  /* Optional: Add border radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Optional: Add box shadow for a 3D effect */
}

.card-back {
  /*background-image: url('https://marketplace.canva.com/EAFJBHqvlTo/1/0/1131w/canva-cream-floral-page-border-EXzZHJEaCys.jpg'); /* URL of the background image */
  transform: rotateY(180deg);
  /* We initially flip this card-back to give an effect of this card being flipped */
  border-radius: 10px;
  width: 100%;
  height: 100%;

}


.card-container.flip .card-front {
  transform: rotateY(180deg);
}

.card-container.flip .card-back {
  transform: rotateY(0deg);
}

@media only screen and (max-width: 768px) {

  .indCards {
    /* Set desired width for mobile view */
    width: 90vw;
    flex-basis: 90vw;
    /* Example: 100% width with 10px margin on each side */
  }

}


/* Two column list */

.two-column-list ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  column-gap: 10px;
  /* Adjust the gap between columns */
  list-style-type: disc;
}

.two-column-list li {
  margin-bottom: 10px;
  /* Add space between items */
  list-style-position: inside;
  /* Ensure bullet points are inside the columns */
}

@media only screen and (max-width: 600px) {
  .two-column-list li {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
}

@media only screen and (max-width: 480px) {
  .two-column-list li {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
}

/* End Two column list */

/* -- END CARD STYLINGS -- */

/* Carousel stylings */

.eventCardsContainer {
  height: 87vh;
  max-height: 750px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #38422B;
  /* background-color: grey; */
  padding: 10px;
  flex-direction: row;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 10px 10px 10px 10px #6E7753;
}

.indCards {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 5px;
  flex-grow: 1;
  flex-shrink: 1;
  box-sizing: border-box;
  border-radius: 20px;
  margin-bottom: 30px;
  /* Ensure padding and border are included in the width */
}

.indCardBody {
  height: 41vh; 
  max-height: 375px; /* Basically modified to have the vh perfectly fit and also the max-height for bigger screens */
  overflow: scroll;
  border: solid;
  margin-bottom: '5px';
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #F6F6DB; /* F6F6DB 38422B */
  overflow-x: hidden;
}

.card-title{
  text-align: center;
}

.card-text{
  color: 38422B;
}

.indCardsbtn {
  margin: 10px;
  background-color:#38422B;
  border-color: #697157;
  color: white;
  width: 200px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
  box-shadow: 4px 4px 4px 4px  #4b503e;
}

.indCardsbtn:hover {
  background-color: #5e6b4c;
}

::-webkit-scrollbar {
  width: 5px;
}

.indCardBody::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* -- Font styles START -- */
@font-face {
  font-family: 'Kalam';
  src: url('./fonts/Kalam-Bold.ttf') format('ttf'),
    url('./fonts/Kalam-Light.ttf') format('ttf'),
    url('./fonts/Kalam-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* -- Font styles END -- */

/* -- Pop up survery START -- */

.survey-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.survey-popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close-btn {
  position: absolute;
  width: 15%;
  top: 10px;
  right: 10px;
  font-size: .5rem;
  cursor: pointer;
  background-color: #a5a8a3;

}

/* -- Pop up survey END -- */




/* -- Survey Form Start -- */

.survey-form {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 50px auto;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #a8a4a4;
  border-radius: 4px;
  box-sizing: border-box;
}

/* -- Survey Form End -- */





/* -- Global -- */
.btn-primary {
  background-color: #6E7753;
  border-color: #697157;
}

.btn-primary:hover {
  background-color: #38422B;
}

/* -- Admin Controls -- */
.fixed-create-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px 20px;
  border-radius: 50%;
  background-color: #38422B;
  /* Adjust color as needed */
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  z-index: 100;
  /* Ensure button is above other elements */
}

.create-form-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Center the form */
  background-color: white;
  border-radius: 10px;
  /* Adjust as needed */
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 101;
  /* Ensure form is above other elements */
  max-height: 80vh;
  width: 80vw;
  overflow: scroll;
  /* Optional: Set a maximum width */
}

/* -- End Admin Controls --*/

/* -- heroBanner -- */

.heroBanner {
  position: relative;
  height: 70vh;
  max-height: 450px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
 
.content {
  position: relative;
  z-index: 1;
  /* Ensures content is above the image */
}
 
.semicircle {
  text-align: center;
  color: #ECFADC;
  overflow: hidden;
  z-index: 1;
  /* Additional styles for semicircle */
}
 
.typer {
  position: relative;
  color: #ceddbf;
  transition: 0.5s;
  animation: typer 1s forwards;
  z-index: 1;
}
 
@keyframes typer {
  0%{ left: -50%; }
  100%{ left: 0%; }
}
 
.heroBanner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(56, 66, 43, 0.349);
  z-index: 0;
  /* Ensures the overlay is between the image and the text */
}

/* -- heroBanner END -- */